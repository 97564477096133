import { useEffect, useState } from "react";
import { ProductCarousel } from "./ProductCarousel";
import { useRouter } from "next/router";
import type { AlgoliaItem } from "@lib/analytics/analyticsTypes";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import hexRgb from "hex-rgb";
import type { IPagePath } from "@contentful-api/types/contentful";
import type { Document } from "@contentful/rich-text-types";
import { LogTag, Logger, ServiceType } from "@lib/monitoring/logger";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useApi } from "@lib/helpers/useApi";
import { QueryKeys } from "@lib/enums/QueryKeys";
import { FetchServiceAxios } from "@lib/helpers/fetchServiceAxios";

interface ISearchProductsHitsToCarouselProps {
    products?: string[];
    headline?: string;
    assistiveMessage?: string;
    sortingOrder?: string[];
    carouselType?: string;
    authorizationInvitation?: Document;
    extraLink?: IPagePath;
    extraLinkLabel?: string;
    id?: string;
}

export const SearchProductsHitsToCarousel = (props: ISearchProductsHitsToCarouselProps) => {
    const {
        products,
        headline,
        assistiveMessage,
        sortingOrder,
        carouselType,
        authorizationInvitation,
        extraLink = null,
        extraLinkLabel = null,
        id,
    } = props;

    const { locale } = useRouter();
    const [hits, setHits] = useState<any[]>([]);

    const getAlgoliaProducts = async () => {
        return await FetchServiceAxios.get<any>(
            `/api/algolia-browse?${new URLSearchParams({ products: products.join(","), locale })}`
        );
    };

    const {
        data: hitsResponse,
        isLoading,
        error,
    } = useApi.useAsync<any>(
        [QueryKeys.algoliaProducts, products, locale],
        () => getAlgoliaProducts(),
        {
            // We trigger the query only if there are products
            enabled: products.length > 0,
        }
    );

    useEffect(() => {
        if (hitsResponse) {
            setHits(hitsResponse);
        }
        if (error) {
            Logger.error(ServiceType.WEB, "Error fetching products:", {
                tag: LogTag.PRODUCT,
                filter: products.join(","),
                error: error,
            });
        }
    }, [hitsResponse]);

    const fixedHits = hits.map((hit, index) => {
        return {
            ...hit,
            id: hit.id ?? hit.objectID,
            productColors: Array.isArray(hit.productColors)
                ? hit.productColors?.map((it) => ({
                      priceWithDiscount: it.priceRaw,
                      priceWithoutDiscount: it.listPriceRaw,
                      image: it.image,
                      objectID: it.objectID,
                      colorCode: it.colorCode,
                      imageBackgroundColor: it.imageBackgroundColor,
                      discountPercentage: it.discountPercentage,
                      hasDiscount: it.hasDiscount,
                      hasStock: it.hasStock,
                  }))
                : hit.productColors,
            queryID: hit.__queryID,
            index,
            productName: hit.name,
            priceWithoutDiscount: hit.listPriceRaw,
            priceWithDiscount: hit.priceRaw,
            backgroundColor: hexRgb("FFF"),
            imageBackgroundColor: hexRgb((hit.backgroundColor ?? "FFF") as string, {
                format: "css",
                alpha: 1,
            }),
        };
    });

    if (sortingOrder && sortingOrder?.length) {
        fixedHits?.sort?.((a, b) => {
            return (
                sortingOrder?.findIndex((it) => it === a.objectID) -
                sortingOrder?.findIndex((it) => it === b.objectID)
            );
        });
    }

    useEffect(() => {
        const analyticsTimeout = setTimeout(
            () =>
                AnalyticsClient.loadItemsList(fixedHits as unknown as AlgoliaItem[], {
                    listName: carouselType,
                }),
            3000
        );
        return () => {
            clearTimeout(analyticsTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixedHits]);

    const ExtraLinkComponent = () => {
        const { completeSlug, label } = extraLink?.fields ?? {};
        if (completeSlug) {
            return <NextLink href={completeSlug}>{extraLinkLabel ?? label}</NextLink>;
        }
        return null;
    };

    //TODO: INTL assistiveMessage
    return (
        <ProductCarousel
            slideData={fixedHits}
            isLoading={isLoading}
            headline={headline}
            assistiveMessage={assistiveMessage}
            carouselType={carouselType}
            authorizationInvitation={authorizationInvitation}
            extraLink={<ExtraLinkComponent />}
            id={id}
        />
    );
};
