import { useBreakpointValue } from "@chakra-ui/react";
import type { FC, ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import { ReadMore } from "../../../components/content/read-more/ReadMore";
import { Text } from "../../content/text/Text";
import { Box } from "../../layout/box/Box";
import { Flex } from "../../layout/flex/Flex";
import { NextImage } from "../../media-and-icons/image/NextImage";
import { gradientBox, styles, textWrapper } from "./StoryTileStyles";

interface IStoryTileProps {
    imageSrc: string;
    imageAlt: string;
    title?: string;
    description?: string;
    columns: number;
    onInView?: () => void;
    showMoreLabel?: string;
    showLessLabel?: string;
    ariaMore?: string;
    ariaLess?: string;
    links?: ReactNode | null | undefined;
    whiteBackground?: boolean;
}

export const StoryTile: FC<IStoryTileProps> = ({
    imageSrc,
    imageAlt,
    title,
    description,
    columns,
    onInView,
    showMoreLabel,
    showLessLabel,
    ariaLess = "",
    ariaMore = "",
    links = null,
    whiteBackground = false,
}) => {
    const { ref } = useInView({
        threshold: 0.5,
        onChange: (inView) => {
            if (inView && onInView) {
                setTimeout(() => onInView(), 2000);
            }
        },
    });

    const onClickReadMore = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const isMobile = useBreakpointValue({ base: true, sm: false });
    const columnWidth = columns ? 100 / columns : 50;

    return (
        <Flex flexDirection="column" ref={ref} test-id={123} w="full">
            <Box position="relative" h={"100%"}>
                {whiteBackground ? (
                    <Box
                        bg="white"
                        h="100%"
                        display="flex"
                        aspectRatio={{ base: "auto", xl: "4/5" }}
                    />
                ) : (
                    <NextImage
                        src={imageSrc}
                        alt={imageAlt || title || ""}
                        sizes={["100vw", "100vw", `${columnWidth}vw`]}
                        sx={{
                            aspectRatio: ["4/5"],
                        }}
                        fill={true}
                        objectFit={"cover"}
                    />
                )}
                <Box
                    sx={{
                        position: {
                            base: whiteBackground ? "relative" : "absolute",
                            xl: "absolute",
                        },
                        height: "100%",
                        top: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        width: "100%",
                    }}
                >
                    <Box sx={styles.boxWrapper}>
                        {(title || description || links) && (
                            <Box sx={gradientBox(whiteBackground)}>
                                <Flex sx={textWrapper(whiteBackground)}>
                                    <Box sx={{ mb: [6, 8] }}>
                                        <Text
                                            color={whiteBackground ? "black" : "white"}
                                            fontWeight={"bold"}
                                            fontSize={[
                                                "mobileH1",
                                                whiteBackground ? "desktopH1" : "desktopH2",
                                                whiteBackground ? "desktopH1" : "desktopH2",
                                            ]}
                                        >
                                            {title}
                                        </Text>
                                        {description && (
                                            <ReadMore
                                                ariaMore={ariaMore}
                                                ariaLess={ariaLess}
                                                sx={styles.readMore}
                                                onClick={onClickReadMore}
                                                showMoreLabel={showMoreLabel}
                                                showLessLabel={showLessLabel}
                                                collapsedHeight={isMobile ? "2.5rem" : "4rem"}
                                                zIndex={10}
                                                showButtonStyle={styles.showButton}
                                                text={description}
                                                textStyle={{
                                                    ...styles.description,
                                                    color: whiteBackground ? "black" : "white",
                                                }}
                                            />
                                        )}
                                    </Box>
                                    {links}
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};
