import slugify from "slugify";
import { getCompleteSlug } from "@lib/utils/getCompleteSlug";
import { getPagePathByLocale, productDetailPage } from "@lib/constants/pagePaths";

export interface PDPSlugParts {
    sku: string;
    slug: string;
    distributionChannelWhereClause: string;
}

export const getPDPSlug = (
    productName: string,
    articleNumber: string,
    colorCode: string,
    locale: string
): PDPSlugParts => {
    const distributionChannelId = process.env.NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID;

    if (!distributionChannelId) {
        throw Error("missing NEXT_PUBLIC_DISTRIBUTION_CHANNEL_ID");
    }

    return {
        sku: `${articleNumber}${colorCode}`,
        slug: getCompleteSlug(
            [slugify(productName?.toLowerCase() || ""), articleNumber, colorCode],
            getPagePathByLocale(productDetailPage, locale)
        ),
        distributionChannelWhereClause: `productSelection(id=\"${distributionChannelId}\")`,
    };
};
