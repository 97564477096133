import { Market } from "./types";

interface CMSSettingsEntry {
    contentfulAuthorizationPageMicrocopySetKey: string;
    contentfulShoppingBagMicrocopySetKey: string;
    contentfulSharedMicrocopySetKey: string;
    contentfulAdyenMicrocopySetKey: string;
    contentfulPDPMicrocopySetKey: string;
}

const siteSettingsDefaultValues: CMSSettingsEntry = {
    contentfulAuthorizationPageMicrocopySetKey: "pageAuthorization",
    contentfulShoppingBagMicrocopySetKey: "pageShoppingBag",
    contentfulSharedMicrocopySetKey: "shared",
    contentfulAdyenMicrocopySetKey: "adyenPayment",
    contentfulPDPMicrocopySetKey: "pageProductDetailPage",
};

const cmsSettingsConfig: Record<Market, CMSSettingsEntry & { contentfulLocaleTagId: string }> = {
    [Market.MY]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnMy",
    },
    [Market.SG]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnSg",
    },
    [Market.ES]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEsEs",
    },
    [Market.EE]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEtEe",
    },
    [Market.IN]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnIn",
    },
    [Market.KR]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeKoKr",
    },
    [Market.CA]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnCa",
    },
    [Market.US]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnUs",
    },
    [Market.AU]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeEnAu",
    },
    [Market.JP]: {
        ...siteSettingsDefaultValues,
        contentfulLocaleTagId: "localeJaJp",
    },
};

const country = process.env.NEXT_PUBLIC_HOSTNAME.split(".")[0] as Market;
const currentSiteSettings = cmsSettingsConfig[country];

if (!currentSiteSettings) {
    throw new Error(`No Config found for hostname ${process.env.NEXT_PUBLIC_HOSTNAME}`);
}

export const getSiteSettings = currentSiteSettings;

export const isProduction = process.env.NODE_ENV === "production";
