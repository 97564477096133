import { Link } from "@components/cms/link/Link";
import type { IModuleStoryTileGrid } from "@contentful-api/types/contentful";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { StoryGrid } from "@ui/components/editorial/story-grid/StoryGrid";
import { StoryTile } from "@ui/components/editorial/story-tile/StoryTile";
import { Button } from "@ui/components/forms/button/Button";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { Fragment } from "react";

interface IStoryTileGridProps extends IModuleRenderedProps<IModuleStoryTileGrid> {}

export const StoryTileGrid: FC<IStoryTileGridProps> = ({ module, index }) => {
    const gridTitle = module?.fields?.title;
    const gridId = module?.sys.id;
    const gridDescription = module?.fields?.description;
    const textAlign = module?.fields?.textAlignment;
    const storyTiles = module?.fields?.storyTiles;
    const storyTileGridWidth = module?.fields?.storyTileGridWidth || "full width";
    const columns = storyTiles?.length;

    const { getMultiple } = useMicrocopy();
    const microcopies = (() => {
        return getMultiple(CommonCMS.shared, [
            CommonCMS.generic.readMore,
            CommonCMS.generic.readLess,
        ]);
    })();

    const LinksWrapperComponent = ({ links, whiteBackground }) => {
        const hasOneLink = !isEmpty(links) && links.length === 1;
        return (
            <Flex pt={["1", "2"]} alignItems="center" gap={"0.2"} width={"full"}>
                {links?.map((link) => (
                    <Button
                        sx={{
                            height: ["9.6", "10.4"],
                            px: ["3", "4"],
                            py: "3",
                            justifyContent: "space-between",
                            alignItems: "center",
                            _hover: {
                                textDecoration: "none",
                            },
                        }}
                        key={link?.sys?.id}
                        // If there is just one link, the whole story tile will be clickable, so to avoid
                        // hydration issues, we don't pass the link to the button component because <a> cannot be descendtand of <a>
                        as={hasOneLink ? undefined : Link}
                        link={link}
                        width={"full"}
                        w={hasOneLink ? "100%" : "auto"}
                        textDecor={"none"}
                        rightIcon={<ECCOIcon name="arrow-right" />}
                        variant={whiteBackground ? "solid" : "standard"}
                        fontSize={["mobileLinkL1", "desktopLinkL1"]}
                        fontWeight="bold"
                    >
                        <span>{link.fields.label}</span>
                    </Button>
                ))}
            </Flex>
        );
    };

    return (
        <Box
            data-contentful-entry-id={module?.sys?.id}
            data-contentful-field-id={"moduleStoryTileGrid"}
        >
            <StoryGrid
                columns={columns}
                title={gridTitle}
                description={gridDescription}
                textAlign={textAlign}
                width={storyTileGridWidth}
            >
                {storyTiles?.map?.((storyTile, i) => {
                    const fields = storyTile?.fields;
                    const id = storyTile?.sys?.id;
                    const links = fields?.links;
                    const image = fields?.image;
                    // TODO: Remove "link" field from CMS and update code accordingly. This is a workaround so...
                    // editors don't have to update all the modules they are currently using manually.
                    // We will need a migration script for move content from "link" to "links" field.
                    const link = fields?.link;
                    const imageSrc = image?.fields?.file?.url;
                    const title = fields?.title;
                    const imageAlt = image?.fields?.description;
                    const linkDescription = fields?.description;
                    // const hasOneLink = !isEmpty(links) && links.length === 1;
                    // const singleLink = hasOneLink && links[0];
                    const hasOneLink = !isEmpty(link);
                    const singleLink = hasOneLink && link;
                    const whiteBackground = fields?.whiteBackground || false;
                    const localMicro = (() => {
                        return getMultiple(
                            CommonCMS.shared,
                            [CommonCMS.generic.readMoreAria, CommonCMS.generic.readLessAria],
                            { replacements: { title } }
                        );
                    })();

                    return (
                        <Fragment key={`${id} - ${i}`}>
                            {/* If there is one link, the whole story tile will be clickable. */}
                            {hasOneLink ? (
                                <Link
                                    link={singleLink}
                                    w="full"
                                    h="fit-content"
                                    order={[!whiteBackground && 2, "inherit"]}
                                    textDecoration="none"
                                    _hover={{
                                        textDecoration: "none",
                                    }}
                                    onClick={() =>
                                        AnalyticsClient.selectPromotion({
                                            image: imageSrc,
                                            title: title,
                                            index: i,
                                            id: gridId + id,
                                        })
                                    }
                                >
                                    <StoryTile
                                        ariaLess={localMicro[CommonCMS.generic.readLessAria]}
                                        ariaMore={localMicro[CommonCMS.generic.readMoreAria]}
                                        showLessLabel={microcopies[CommonCMS.generic.readLess]}
                                        showMoreLabel={microcopies[CommonCMS.generic.readMore]}
                                        imageSrc={imageSrc}
                                        imageAlt={imageAlt}
                                        title={title}
                                        description={linkDescription}
                                        columns={columns}
                                        onInView={() =>
                                            AnalyticsClient.viewPromotion({
                                                image: imageSrc,
                                                title: title,
                                                index: i,
                                                id: gridId + id,
                                            })
                                        }
                                        whiteBackground={whiteBackground}
                                        links={
                                            <LinksWrapperComponent
                                                whiteBackground={whiteBackground}
                                                links={[link]}
                                            />
                                        }
                                    />
                                </Link>
                            ) : // If there is more than one link, we pass the links wrapper component to the story tile. Otherwise return the story tile without links
                            links?.length > 1 ? (
                                <Box
                                    order={{ base: !whiteBackground && 2, xl: "inherit" }}
                                    // If editors uses this module as Hero, and if has white background, the first story tile should have a margin top to avoid text going behind navigation
                                    pt={{
                                        base: whiteBackground && index === 0 && 16,
                                        xl: "initial",
                                    }}
                                >
                                    <StoryTile
                                        showLessLabel={microcopies[CommonCMS.generic.readLess]}
                                        showMoreLabel={microcopies[CommonCMS.generic.readMore]}
                                        imageSrc={imageSrc}
                                        imageAlt={imageAlt}
                                        title={title}
                                        description={linkDescription}
                                        columns={columns}
                                        links={
                                            <LinksWrapperComponent
                                                whiteBackground={whiteBackground}
                                                links={links}
                                            />
                                        }
                                        whiteBackground={whiteBackground}
                                    />
                                </Box>
                            ) : (
                                <Box order={{ base: !whiteBackground && 2, xl: "inherit" }}>
                                    <StoryTile
                                        showLessLabel={microcopies[CommonCMS.generic.readLess]}
                                        showMoreLabel={microcopies[CommonCMS.generic.readMore]}
                                        imageSrc={imageSrc}
                                        imageAlt={imageAlt}
                                        title={title}
                                        description={linkDescription}
                                        columns={columns}
                                        whiteBackground={whiteBackground}
                                    />
                                </Box>
                            )}
                        </Fragment>
                    );
                })}
            </StoryGrid>
        </Box>
    );
};
